import { render, staticRenderFns } from "./LobbyPage.vue?vue&type=template&id=b05132ea&"
import script from "./LobbyPage.vue?vue&type=script&lang=js&"
export * from "./LobbyPage.vue?vue&type=script&lang=js&"
import style0 from "./LobbyPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports