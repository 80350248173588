<template>
  <VFBLoginScope
    :app-id="facebookAppId"
    @login="connectToFacebook"
    @sdk-init="$emit('facebook-working')"
  >
    <button
      id="facebook-connect-button"
      slot-scope="scope"
      class="button-facebook-connect d-inline-flex align-items-center py-1"
      @click="handleFbLogin(scope)"
    >
      <img
        draggable="false"
        src="@/assets/img/common/facebook.png"
        class="token"
      />
      <slot
        v-if="scope.disconnected || (scope.connected && !scope.working)"
        name="login"
        ><p class="facebook-connect-text">
          {{ $t("dailylogin.facebook_button") }}
        </p>
        <div class="facebook-connect-amount">
          +50.000
          <img
            draggable="false"
            src="@/assets/img/common/coin1.png"
            style="width: 24px; height: 24px"
            class="facebook-connect-icon ml-1"
          />
        </div>
      </slot>

      <slot v-else-if="scope.working && scope.disconnected" name="working">
        <div class="facebook-btn-text text-center">
          <p>{{ $t("login.web.facebook_button.waiting") }}</p>
        </div></slot
      >
    </button></VFBLoginScope
  >
</template>

<script>
import * as profileAPI from "@/API/profile.js";
import { VFBLoginScope } from "vue-facebook-login-component";

export default {
  name: "FacebookConnectButton",
  components: { VFBLoginScope },
  data() {
    return {
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
    };
  },
  computed: {},

  methods: {
    handleFbLogin(scope) {
      scope.login();
    },
    connectToFacebook(e) {
      if (e.status === "connected") {
        profileAPI
          .connectToFacebook(e.authResponse.accessToken)
          .then((res) => {
            this.$store.commit("user/updateUserObject", res.data.data.user);
            this.$emit("facebookConnectSuccess");
          })
          .catch((e) => {
            this.$emit("facebookConnectError");

            if (e.data.message) {
              if (e.data.id === "socialuser_already_on_different_user") {
                this.$store.commit("popups/setAlertBannerContent", {
                  alertHeading: this.$t("alerts.title.error"),
                  alertText: this.$t("error.fb_already_on_different_user"),
                  type: "danger",
                  variant: "danger",
                });
              } else {
                this.$store.commit("popups/setAlertBannerContent", {
                  alertHeading: this.$t("alerts.title.error"),
                  alertText: e.data.message,
                  type: "danger",
                  variant: "danger",
                });
              }
            } else {
              this.displayErrorMessage();
            }
          });
      } else {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
#facebook-connect-button {
  width: 100%;
  .facebook-connect-text {
    margin: 0;
    line-height: 1;
    font-size: 12px;
  }
}
</style>
