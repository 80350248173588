<template>
  <b-modal
    :id="modalId"
    ref="modal-winter-bonus-jagd-winner"
    :hide-footer="true"
    modal-class="modal-winter-bonus-jagd-winner"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <Spinner
        v-if="loading"
        size="medium"
        context="Loading Event..."
        classes="dead-center"
      ></Spinner>
      <div class="preload"></div>
      <div class="bg">
        <animation-spine-winterbonusjagd-winner-bg />
      </div>

      <div class="logo to-center-abs">
        <div class="main"></div>
        <div class="bonus" :class="$store.state.locale"></div>
      </div>

      <div class="ticket-panel">
        {{ $t("events.winter.winner.ticket_panel") }}
      </div>

      <div class="prices to-center-abs"></div>

      <div class="win1">
        <div class="headline">
          {{ $t("events.winter.winner.price1") }}
        </div>
        <div class="winners">
          <div
            v-for="winner in winnersPrice1"
            :key="winner.user_id"
            class="name clickable"
            @click="fetchPublicUser(winner.user_id)"
          >
            {{ winner.name }}
          </div>
        </div>
      </div>

      <div class="win2">
        <div class="headline">
          {{ $t("events.winter.winner.price2") }}
        </div>
        <div class="winners">
          <div
            v-for="winner in winnersPrice2"
            :key="winner.user_id"
            class="name clickable"
            @click="fetchPublicUser(winner.user_id)"
          >
            {{ winner.name }}
          </div>
        </div>
      </div>

      <div class="win3">
        <div class="headline">
          {{ $t("events.winter.winner.price3") }}
        </div>
        <div class="winners">
          <div
            v-for="winner in winnersPrice3"
            :key="winner.user_id"
            class="name clickable"
            @click="fetchPublicUser(winner.user_id)"
          >
            {{ winner.name }}
          </div>
        </div>
      </div>

      <div class="foot-note to-center-abs">
        {{ $t("events.winter.winner.foot_note") }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import Spinner from "@/components/animation/animation-spinner.vue";
import AnimationSpineWinterbonusjagdWinnerBg from "@/components/animation/animation-spine-winterbonusjagd-winner-bg";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";

export default {
  name: "ModalWinterBonusJagdWinner",
  components: {
    AnimationSpineWinterbonusjagdWinnerBg,
    Spinner,
  },
  mixins: [eventPopups, getPublicUserInfos],
  props: {
    modalId: {
      type: String,
      default: "modal-winter-bonus-jagd-winner",
    },
  },

  data() {
    return {
      intervalId: null,
      loading: false,
    };
  },

  computed: {
    winnersPrice1() {
      return [{ name: "Harburger", user_id: 26095 }];
    },
    winnersPrice2() {
      return [{ name: "ole", user_id: 56484 }];
    },

    winnersPrice3() {
      return [
        { name: "dollarix", user_id: 30201 },
        { name: "bitte44", user_id: 110566 },
      ];
    },
  },
  mounted() {
    // this.$bvModal.show("modal-winter-bonus-jagd-winner");
  },

  methods: {
    onClose() {
      this.$store.commit("popups/setNextPipelineQueueStep");
      this.$store.commit("events/setBadgeAnimation", {
        id: "modal-winter-bonus-jagd-winner",
        runAnimation: false,
      });
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1390.17px;
$modal-height: 825.67px;

.modal-winter-bonus-jagd-winner {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 820px), (max-width: 1250px) {
      transform: scale(0.9) !important;
      top: -50px;
      margin-bottom: -10%;
    }
    @media (max-height: 730px), (max-width: 1100px) {
      transform: scale(0.8) !important;
      top: -130px;
      margin-bottom: -20%;
    }

    @media (max-width: 1250px) {
      left: -100px;
    }
  }

  .modal-header {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: none !important;

    .close-button {
      width: 36px;
      height: 36px;
      right: 34px;
      top: 64px;
      z-index: 999;
      background: url(~@/assets/img/events/winter-bonus-jagd/btn-close.png)
        no-repeat;
      background-size: 100% 100%;
    }

    p {
      z-index: 1;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    color: white;
    text-align: center;
    z-index: 0;
    border: none;
    border-radius: 0;
  }

  .modal-body {
    width: $modal-width;
    height: $modal-height;
    margin-top: 3%;
  }
}
</style>

<style scoped lang="scss">
.modal-winter-bonus-jagd-winner {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/winter-bonus-jagd/btn-help-down.png),
      url(~@/assets/img/events/winter-bonus-jagd/btn-unlock-level-down.png),
      url(~@/assets/img/events/winter-bonus-jagd/btn-kingspass-down.png);
  }

  * {
    font-family: Ubuntu-Bold;
    text-transform: uppercase;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }

  .logo {
    position: absolute;
    top: 5px;
    z-index: 30;
    width: 344px;

    .main {
      width: 463px;
      height: 200px;
      position: absolute;
      left: -320px;
      top: -25px;
      z-index: 20;
      background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
        right/100% 100% no-repeat;
    }

    .bonus {
      width: 517px;
      height: 182px;
      position: absolute;
      left: -200px;
      top: 82px;
      z-index: 21;

      &.en {
        background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
          right/100% 100% no-repeat;
      }

      &.de {
        background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
          right/100% 100% no-repeat;
      }
    }
  }

  .ticket-panel {
    position: absolute;
    top: -10px;
    right: 207px;
    width: 316px;
    height: 219px;
    background: url(~@/assets/img/events/winter-bonus-jagd/wbj-winscreen-infopanel.png)
      center/100% 100% no-repeat;
    line-height: 1.2;
    padding: 110px 22px 20px 22px;
    color: white;
    font-size: 21px;
  }

  .prices {
    top: 325px;
    width: 937px;
    height: 241px;
    background: url(~@/assets/img/events/winter-bonus-jagd/wbj-winscreen-rewards-2022.png)
      right/100% 100% no-repeat;
  }

  .headline {
    font-size: 26px;
    text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
      0 0 18px rgba(77, 229, 255, 0.5);
    width: 260px;
    line-height: 1.2;
  }

  .name{
    line-height:1.2;
  }

  .win1 {
    position: absolute;
    top: 275px;
    left: 280px;

    .headline {
      padding-bottom: 5px;
    }

    .name {
      font-size: 18px;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
    }
  }

  .win2 {
    position: absolute;
    top: 245px;
    left: 530px;

    .headline{
      width:330px;
    }

    .name {
      font-size: 22px;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
    }
  }

  .win3 {
    position: absolute;
    top: 275px;
    left: 840px;

    .headline {
      padding-bottom: 5px;
    }

    .name {
      font-size: 18px;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
    }
  }

  .foot-note {
    top: 530px;
    width: 734px;
    height: 90px;
    background: url(~@/assets/img/events/winter-bonus-jagd/wbj-winscreen-info-bottom-bg.png)
      center/100% 100% no-repeat;
    line-height: 1.2;
    padding: 23px 20px 20px 20px;
    color: #99ccd5;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    text-transform: none;
  }
}
</style>
