<template>
  <b-modal
    id="modal-newsletter-confirm"
    modal-class="alert-window"
    :hide-footer="true"
  >
    <template v-slot:modal-header>
      <div
        role="button"
        href="#"
        class="close-button"
        @click="$bvModal.hide('modal-newsletter-confirm')"
      ></div>
      <DecoCorners color-variant="blue" />
      <div class="super-headline3">
        {{ $t("alerts.newsletter_subscribe.headline") }}
      </div>
    </template>
    <template slot="default">
      <div class="modal-contents">
        <p class="modal-prompt mt-3">
          {{
            $t("profile.newsletter.text_new", {
              0: formatNumber(confirmReward, true),
            })
          }}
        </p>

        <div class="abo-section text-center ml-auto mr-auto">
          <div
            class="abo-button btn ok-button-green to-center-abs"
            @click="changeNewsletter"
          >
            {{ $t("alerts.newsletter_subscribe.button_text") }}
          </div>
        </div>
      </div>
      <div id="newsletter-icon" class="position-absolute bg-img"></div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import * as profileAPI from "@/API/profile";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";

export default {
  name: "ModalNewsletterConfirm",
  components: { DecoCorners },
  mixins: [AnimationFullRewardEarned],
  computed: {
    confirmReward() {
      let settings = this.$store.state.generalConfig.settings;
      if (settings["user.newsletter.gold"]) {
        return settings["user.newsletter.gold"];
      }

      return 100000000;
    },

    newsletterSubscribed() {
      return this.$store.getters["user/currentUser"].newsletter;
    },
  },
  methods: {
    changeNewsletter() {
      profileAPI
        .subscribeNewsletter()
        .then(() => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertHeading: this.$t("alerts.title.success"),
            alertText: this.$t("profile.newsletter.subscribed"),
            type: "check",
          });
          this.firebaseClickItemProfile("popup_receive_newsletter_yes_button");

          //show reward show
          let rewards = [
            { reward: "gold", amount: this.formatNumber(this.confirmReward) },
          ];
          this.AnimationFullRewardEarnedAnimate(rewards, false);

          //update user
          this.$store.dispatch("user/updateUser").then(() => {
            this.$bvModal.hide("modal-newsletter-confirm");
          });
        })
        .catch((e) => {
          console.log(e);
          this.displayErrorMessage();
        });
    },
  },
};
</script>
<style lang="scss">
#modal-newsletter-confirm {
  margin-top: 100px;
  .close-button {
    right: -30px;
    top: -29px;
    width: 60px;
    height: 60px;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 44px;
    .super-headline3 {
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
    }
  }
  .modal-dialog {
    max-width: 734px;
  }
  .modal-content {
    min-height: 370px;
    color: white;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(#205f72, #050d1c),
      linear-gradient(#050d1c, #205f72);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
  }

  .modal-contents {
    width: 100%;

    min-height: 128px;
    .super-headline3 {
      font-size: 28.8px;
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51, #8b5a00);
      filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.5))
        drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    }
    .modal-prompt {
      padding: 0 100px;
      font-size: 24px;
    }
    #reward-display {
      width: 383px;
      height: 62px;
      background-image: url(~@/assets/img/common/reward-display.svg);
      margin-bottom: 96px;
      img {
        height: 47px;
      }
      span {
        font-size: 48px;
        text-shadow: 0 0 10px rgba(77, 229, 255, 0.53),
          0 0 2px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.8);
      }
    }
  }

  #newsletter-icon {
    width: 265px;
    height: 229px;
    background-image: url(~@/assets/img/profile/graphic-newsletter-coins@2x.png);
    right: -55px;
    bottom: -55px;
    z-index: 10;
  }

  .abo-section {
    height: 160px;

    .abo-button {
      width: 400px;
      font-size: 20px;
      color: white;
      line-height: 1.2;
    }
  }
}
</style>
