import { fetchRandomGame } from "@/API/games.js";

export default {
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    eventDurationDays() {
      let startDate = new Date(this.eventData.start_at);
      let endDate = new Date(this.eventData.end_at);
      return this.getTimeDiffDays(startDate, endDate);
    },
    eventDaysLeft() {
      let startDate = new Date();
      let endDate = new Date(this.eventData.end_at);
      return this.getTimeDiffDays(startDate, endDate);
    },

    eventRewards() {
      return this.eventData.rewards;
    },
    eventRaffles() {
      return this.eventData.raffles;
    },
    startTime() {
      return new Date(this.eventData.start_at).getTime();
    },
    endTime() {
      return new Date(this.eventData.end_at).getTime();
    },
    emailConfirmed() {
      return this.$store.getters["user/currentUser"].email_verified_at;
    },
    userEvent() {
      let allUserEvents = this.$store.state.events.userEvents;
      if (!allUserEvents) return null;
      return allUserEvents.find(
        (event) => event.ingame_event_id === this.eventData.id
      );
    },

    numberOfWinners() {
      if (!this.eventData.raffles[0] || !this.eventData.raffles[0].winners[0])
        return null;
      return this.eventData.raffles[0].winners[0].users;
    },
    winners() {
      return this.eventData.finalWinners;
    },
    userIsWinner() {
      return (
        this.winners.findIndex(
          (winner) =>
            winner.user_id === this.$store.getters["user/currentUser"].id
        ) >= 0
      );
    },
    voucherVal() {
      return this.getAmazonVoucherValueByEvent(this.eventData);
    },
  },
  methods: {
    handlePlayNowClick() {
      let randomGameId;
      fetchRandomGame()
        .then((res) => {
          randomGameId = res.data.data.game.id;
        })
        .catch((e) => {
          // use frontend random functionality as fallback
          const games = this.$store.getters["gameplay/playableGames"];
          const maxNum = games.length;
          let index = Math.floor(Math.random() * (maxNum + 1));
          randomGameId = games[index].id;
          console.log(e);
        })
        .finally(() => {
          this.$bvModal.hide(this.modalId);
          this.$router.push({ name: "game", params: { gameId: randomGameId } });
        });
    },
    handleSupportClick() {
      this.$bvModal.hide(this.modalId);
      this.$router.push("/contact");
    },
    getTimeDiffDays(start, end) {
      let timeDiff = end.getTime() - start.getTime();
      return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    },
    getRewardIcon(reward) {
      // if (reward.asset.endsWith("Card")) {
      // } else if (reward.asset.endsWith("Timer")) {
      //   return "timer";
      // } else

      if (reward.asset.endsWith("Currency")) {
        // will return either "gold" or "crown"
        if (reward.type === "gold") {
          return require("@/assets/img/common/coin1.png");
        } else if (reward.type === "crown") {
          return require("@/assets/img/common/crown6.png");
        }
      } else {
        return null;
      }
    },
    handleEmailConfirmClick() {
      this.$bvModal.hide(this.modalId);
      this.$router.push("/profile");
    },
    handleEventCountdownEnd() {
      this.$$bvModal.hide(this.modalId);
      this.$store.dispatch("events/getEvents");
    },
    getEventTypeName(typeString) {
      if (typeString.endsWith("SlotSpinIngameEventType")) {
        return "spinwin";
      } else if (typeString.endsWith("LevelUpIngameEventType"))
        return "levelup";
      else if (typeString.endsWith("PaymentIngameEventType")) return "payment";
      else if (typeString.endsWith("SportBetIngameEventType"))
        return "bundesliga";
      else if (typeString.endsWith("WINTER")) return "winter";
      else {
        return null;
      }
    },
    getPassTypeName(titleString) {
      if (titleString.startsWith("Winter")) {
        return "winterbonusjagd";
      } else if (titleString.startsWith("Summer Event")) {
        return "summerbonusjagd";
      } else if (titleString.startsWith("Olympia")) {
        return "olympia";
      } else {
        return "";
      }
    },
    getAmazonVoucherValueByEvent(event) {
      if (!event.raffles[0]) return null;
      else {
        const title = event.raffles[0].rewards[0].asset.title;
        if (!title.includes("Amazon Voucher ")) {
          return 100;
        }
        return title.replace("Amazon Voucher ", "");
      }
    },
    onOpen() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("events/updateUserEvent", this.eventData.id)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    onClose() {
      console.log(this.modalId);
      this.$store.commit("events/setBadgeAnimation", {
        id: this.modalId,
        runAnimation: false,
      });
    },
  },
};
