<template>
  <div id="broadcasting"></div>
</template>

<script>
import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default {
  name: "Broadcasting",
  data() {
    return {
      echo: null,
    };
  },

  computed: {
    loggedIn: function () {
      return this.$store.getters["auth/isLoggedIn"];
    },
    token: function () {
      return this.$store.getters["auth/userToken"];
    },
    isConnected: function () {
      return (
        this.echo && this.echo.connector.pusher.connection.connection !== null
      );
    },
  },
  watch: {
    loggedIn: {
      handler: function (loggedIn) {
        if (loggedIn) {
          this.connect();
        } else {
          this.disconnect();
        }
      },
    },
    isConnected() {
      this.$emit("socketStatus", this.echo);
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.connect();
    }
  },
  methods: {
    connect() {
      if (!this.echo) {
        this.echo = new Echo({
          broadcaster: "pusher",
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true,
          wsHost: process.env.VUE_APP_WSHOST,
          wsPort: process.env.VUE_APP_WSPORT,
          wssHost: process.env.VUE_APP_WSHOST,
          wssPort: process.env.VUE_APP_WSPORT,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          authEndpoint:
            "https://" + process.env.VUE_APP_DEVSERVER + "/broadcasting/auth",
          auth: {
            headers: {
              Authorization: null,
            },
          },
        });
        this.echo.connector.pusher.connection.bind("connected", (event) => {
          this.connect(event);
        });
        this.echo.connector.pusher.connection.bind("disconnected", () => {
          this.disconnect();
        });
      }
      this.echo.connector.pusher.config.auth.headers.Authorization = this.token;
      this.echo.connector.pusher.connect();
    },
    disconnect() {
      if (!this.echo) {
        return;
      } else {
        this.echo.disconnect();
        this.$emit("logout");
      }
    },
  },
};
</script>
