import { optionsFunction } from "./_config";
const axios = require("axios");

export const get = (game_id) =>
  axios
    .get(`api/jackpots/get?game_id=${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.response.data.data);

export const getAllJackpots = () =>
  axios.get(`api/jackpots/lobby`, optionsFunction());

//slot specific jackpots, id optional
export const getGameJackpots = () =>
  axios.get(`api/jackpots/games`, optionsFunction());

export const listById = (game_id) =>
  axios
    .get(`api/jackpots/list?game_id=${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.response.data.data);

export const lastWinner = () =>
  axios
    .get(`api/jackpots/top/win/last`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const lastWinnerByGameId = (game_id) =>
  axios
    .get(`api/jackpots/top/win/last?game_id=${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const activate = (game_id, jackpot_id) =>
  axios
    .post(
      `api/jackpots/user/activate`,
      {
        game_id: game_id,
        jackpot_id: jackpot_id,
      },
      optionsFunction()
    )
    .then((res) => res.data.data)
    .catch((e) => e.response.data);
