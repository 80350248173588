const axios = require("axios");
const options = {
  baseURL: process.env.VUE_APP_CONFIGBUCKET,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "Application/json",
  },
};
const month = new Date().getMonth();
export const getSettings = () =>
  axios.get(
    `/settings.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getDailyLoginScheme = () =>
  axios.get(
    `/dailylogin.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getLoyalty = () =>
  axios.get(
    `/loyalty.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getLeague = () =>
  axios.get(
    `/leagues.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getCardProbabilities = () =>
  axios.get(
    `/cards.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getUser = () =>
  axios.get(
    `/user.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getCardRaffle = () =>
  axios.get(
    `/card_raffles.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getCardRaffleRewards = () =>
  axios.get(
    `/card_raffle_rewards.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );

export const getChestRewards = () =>
  axios.get(
    `/chests.json?v=${process.env.VUE_APP_VERSION + "." + month}`,
    options
  );
