import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./custom.scss";
import i18next from "i18next";
import VueI18Next from "@panter/vue-i18next";
import AlertWindowMaker from "./custom-plugins/AlertWindowMaker.js";
import Seo from "./custom-plugins/Seo.js";
import CurrentDate from "./custom-plugins/currentDate.js";
import FormatNumber from "./custom-plugins/formatNumber.js";
import ErrorMessage from "./custom-plugins/errorMessage.js";
import ShortenString from "./custom-plugins/shortenString.js";
import SoundSystem from "./custom-plugins/SoundSystem.js";
import VueCookies from "vue-cookies";
import Firebase from "./custom-plugins/Firebase.js";
import GoogleTag from "./custom-plugins/GoogleTagManager.js";
import BranchIo from "./custom-plugins/branchIo.js";
import FeatureUnlockedChecker from "./custom-plugins/featureUnlockedChecker.js";
import VueMeta from 'vue-meta';

const axios = require("axios");
// import locale files
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";

//bootstrap components
import { ModalPlugin } from "bootstrap-vue";
import { CarouselPlugin } from "bootstrap-vue";

Vue.use(ModalPlugin);
Vue.use(CarouselPlugin);
import { PopoverPlugin } from "bootstrap-vue";

Vue.use(PopoverPlugin);
import {
  BRow,
  BNavbar,
  BNavbarNav,
  BNavItem,
  BCol,
  BNav,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BTable,
  BPagination,
  BAlert,
  BSpinner,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";

Vue.component("b-row", BRow);
Vue.component("b-navbar", BNavbar);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-col", BCol);
Vue.component("b-nav", BNav);
Vue.component("b-button", BButton);
Vue.component("b-input-group", BInputGroup);
Vue.component("b-input-group-append", BInputGroupAppend);
Vue.component("b-input", BFormInput);
Vue.component("b-form", BForm);
Vue.component("b-table", BTable);
Vue.component("b-pagination", BPagination);
Vue.component("b-alert", BAlert);
Vue.component("b-spinner", BSpinner);
Vue.component("b-carousel", BCarousel);
Vue.component("b-carousel-slide", BCarouselSlide);

//gsap components
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);
Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

Vue.prototype.$http = axios;
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      if (store.getters.isMaintenanceModeEnabled) {
        store.commit("disableMaintenanceMode");
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    //maintenance error
    if (!error.response) {
      // store.commit("enableMaintenanceMode");
      // router.push("/maintenance");
      return Promise.reject(error.response);
    }

    if (error.response.status) {
      switch (error.response.status) {
        // force logout if unauthenticated
        case 401:
          store.dispatch("destroyStore").then(router.push("/"));
          break;
        case 503:
          // store.commit("enableMaintenanceMode");
          // router.push("/maintenance");
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

Vue.config.performance = true;
Vue.use(VueCookies);
Vue.use(AlertWindowMaker);
Vue.use(Seo);
Vue.use(CurrentDate);
Vue.use(FormatNumber);
Vue.use(ErrorMessage);
Vue.use(ShortenString);
Vue.use(SoundSystem);
Vue.use(Firebase);
Vue.use(GoogleTag);
Vue.use(BranchIo);
Vue.use(FeatureUnlockedChecker);
Vue.use(VueMeta);

// setting up i118next
Vue.use(VueI18Next);
i18next.init({
  lng: store.state.locale,
  resources: {},
  keySeparator: ">",
  saveMissing: true,
  interpolation: {
    prefix: "{",
    suffix: "}",
  },
});
i18next.addResourceBundle("en", "translation", translationEN, true);
i18next.addResourceBundle("de", "translation", translationDE, true);
const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false;
const sessionToken = sessionStorage.getItem("auth_token");
const localToken = localStorage.getItem("auth_token");
if (sessionToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = sessionToken;
} else if (localToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = localToken;
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
