import { Number } from "core-js/web";

const FormatNumber = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        formatNumber(value, shortNumber = false) {
          if (shortNumber) {
            //short number for example: 10.000.000 => 10 M
            if (value >= 1000000 && value <= 999999999) {
              let millions = value / 1000000;
              let number;
              if (value % 1000000 === 0) {
                number = millions;
              } else {
                number = millions.toFixed(3);
              }
              return (
                number +
                " " +
                (this.$store.state.locale === "de" ? "Mio." : "M")
              );
            }
            //short number for example: 10.000.000.000 => 10 B
            if (value >= 1000000000) {
              let billions = value / 1000000000;
              let number;
              if (value % 1000000000 === 0) {
                number = billions;
              } else {
                number = billions.toFixed(2);
              }
              return (
                number +
                " " +
                (this.$store.state.locale === "de" ? "Mrd." : "Bn.")
              );
            }
          }
          // Adds decimal points every three digits

          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatPrice(value) {
          if (this.$store.state.locale === "de") {
            return value.toString().replace(".", ",");
          }
          return value.toString().replace(",", ".");
        },
      },
    });
  },
};
export default FormatNumber;
