import { optionsFunction } from "./_config";
const axios = require("axios");

export const login = () =>
  axios
    .get(`api/dailylogin/login`, optionsFunction())
    .then((res) => {
      return res.data;
    })
    .catch((e) => console.log(e));

// export const logout = () =>
//   axios
//     .get(`api/dailylogin/logout`, optionsFunction())
//     .then(res => res.data.data)
//     .catch(e => console.log(e));

export const randomize = () =>
  axios
    .get(`api/dailylogin/randomize`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const schema = () =>
  axios
    .get(`api/dailylogin/schema`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const collect = () =>
  axios
    .post(`api/dailylogin/collect`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
