const axios = require("axios");
const options = {
  baseURL: "https://" + process.env.VUE_APP_DEVSERVER,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    Version: process.env.VUE_APP_VERSION,
    Platform: "web",
  },
};

export const fetchLoggedInUser = (payload, localeQuery) =>
  axios.post(
    `/api/login?locale=${localeQuery}`,
    {
      email: payload.email,
      password: payload.password,
    },
    options
  );

export const getTokenForLandingPage = (landingToken) =>
  axios.post(
    "/api/login/landingpage",
    {
      token: landingToken,
    },
    options
  );

export const registerNewUser = (payload, localeQuery) =>
  axios.post(
    `/api/register?locale=${localeQuery}`,
    payload,
    options
  );

export const checkNameForBlacklist = (name, locale) =>
  axios.post(
    `/api/register/blacklist?locale=${locale}`,
    { name: name },
    options
  );

export const loginWithFacebook = (token, localeQuery) =>
  axios.post(
    `/api/login/facebook?locale=${localeQuery}`,
    { token: token },
    options
  );

export const fetchPublicConfig = () => axios.get("/api/public/config", options);
