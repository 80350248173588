<template>
  <b-modal
    id="user-details-modal"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
    @hidden="resetModal"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template v-if="user.user" slot="default">
      <div id="player-info-container" class="container-fluid">
        <b-row id="modal-top-row" align-v="center" class="ml-0 mr-0">
          <b-col cols="3" class="avatar-field gradient-indent">
            <img
              draggable="false"
              :src="user.user.avatarUrl"
              class="avatar-img to-center-abs"
            />
          </b-col>
          <b-col class="d-flex upper-col">
            <span class="friend-name">{{
              shortenString(user.user.name, 18)
            }}</span>
            <div v-if="!isItMe" class="friend-status-button">
              <button
                v-if="!user.friendship && !sendRequestClicked"
                class="button-blue-md-icon mx-auto"
                style="width: 85%"
                @click="handleAddFriend"
              >
                <span>{{
                  $t("popup_user_details.button_friend_request")
                }}</span>
                <img src="@/assets/img/common/plus.png" />
              </button>
              <button
                v-else-if="
                  (user.friendship && user.friendship.status === 'OPEN') ||
                  (user.friendship && user.friendship.status === 'PENDING') ||
                  (!user.friendship && sendRequestClicked)
                "
                class="button-blue-md-icon mx-auto w-100"
                @click="
                  openAlertWindow(
                    'revoke-request-modal',
                    $t('popup_revoke_friend.message'),
                    true,
                    $t('popup_revoke_friend.button_yes'),
                    $t('popup_revoke_friend.button_no')
                  )
                "
              >
                <span class="small-line">{{
                  $t("popup_user_details.button_friends")
                }}</span>
                <img src="@/assets/img/common/arrow-yellow.png" />
                <!-- <TwoOptionsModal
                    @cancel="$bvModal.hide('abort-request-modal')"
                    @confirm="handleAbortRequest"
                    prompt="Freundschaftsanfrage zurückziehen?"
                    id="abort-request-modal"
                    cancelButtonText="Fortsetzen"
                    confirmButtonText="Zurückziehen"
                  /> -->
              </button>
              <button
                v-else-if="user.friendship.status === 'ACCEPTED'"
                class="button-blue-md-icon mx-auto w-75"
                @click="
                  openAlertWindow(
                    'end-friendship-modal',
                    $t('popup_remove_friend.message'),
                    true,
                    $t('popup_remove_friend.button_yes'),
                    $t('popup_remove_friend.button_no')
                  )
                "
              >
                <span>{{
                  $t("popup_user_details.button_remove_friends")
                }}</span>
                <img src="@/assets/img/common/check-green.png" />
              </button>
            </div>
          </b-col>
        </b-row>

        <b-row id="badge-row" no-gutters class="mt-3 mb-3">
          <b-col cols="6" class="d-flex h-100 pl-3">
            <div
              class="h-100 w-100 flex-column align-items-center justify-content-center"
            >
              <span class="text-uppercase bold" style="font-size: 14px">
                {{ $t("popup_user_details.user_level") }}
              </span>
              <div class="gradient-indent blue w-75 mx-auto">
                {{ user.user.level }}
              </div>
            </div></b-col
          >
        </b-row>
        <b-row class="modal-bottom-row" align-h="center">
          <b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="5" class="text-left bold pr-0">{{
                $t("popup_user_details.team_name")
              }}</b-col
              ><b-col v-if="user.team" cols="7" class="text-right blue pl-0">{{
                user.team.name
              }}</b-col
              ><b-col v-else cols="7" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          ><b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="6" class="text-left bold pr-0">{{
                $t("popup_user_details.highest_profit")
              }}</b-col
              ><b-col
                v-if="user.stats && user.stats.slot_win_highest"
                cols="6"
                class="text-right blue pl-0"
                >{{ formatNumber(user.stats.slot_win_highest) }}</b-col
              ><b-col v-else cols="6" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          ><b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="6" class="text-left bold pr-0">{{
                $t("popup_user_details.highest_jackpot")
              }}</b-col
              ><b-col
                v-if="user.stats && user.stats.jackpot_highest"
                cols="6"
                class="text-right blue pl-0"
                >{{ formatNumber(user.stats.jackpot_highest) }}</b-col
              ><b-col v-else cols="6" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          ><b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="6" class="text-left bold pr-0">{{
                $t("popup_user_details.latest_success")
              }}</b-col
              ><b-col
                v-if="user.stats && user.stats.achievement_won_last"
                cols="6"
                class="text-right blue pl-0"
                >{{
                  $t(
                    `achievements.achievement_title.${user.stats.achievement_won_last}`
                  )
                }}</b-col
              ><b-col v-else cols="6" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          >

          <b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="6" class="text-left bold pr-0">{{
                $t("popup_user_details.won_tournaments")
              }}</b-col
              ><b-col
                v-if="user.stats && user.stats.tournament_won_increment"
                cols="6"
                class="text-right blue pl-0"
                >{{ user.stats.tournament_won_increment }}</b-col
              ><b-col v-else cols="6" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          ><b-col cols="11" class="gradient-indent mb-1"
            ><b-row
              ><b-col cols="6" class="text-left bold pr-0">{{
                $t("popup_user_details.user_league")
              }}</b-col
              ><b-col v-if="user.league" cols="6" class="text-right blue pl-0"
                >{{ $t(`user_leagues.league.${user.league.title}`) }}-{{
                  $t("teams.join_team.column_title.league")
                }}
                ({{ user.league.position }})</b-col
              ><b-col v-else cols="6" class="text-right blue pl-0"
                >-</b-col
              ></b-row
            ></b-col
          >
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import * as FriendsAPI from "@/API/friends.js";
import { rejectRequest, revokeRequest } from "@/API/friends.js";
export default {
  name: "UserDetailsModal",
  data() {
    return {
      sendRequestClicked: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters["user/getUserSelectedProfile"];
    },
    isItMe() {
      return this.user.user.id === this.$store.getters["user/currentUser"].id;
    },
    userLeagueName() {
      if (this.user.league !== undefined) {
        return (
          this.$t(`user_leagues.league.${this.user.league.title}`) +
          "-" +
          this.$t("user_leagues.league")
        );
      } else return null;
    },
  },
  mounted() {
    this.$root.$on("ok-end-friendship-modal", () => {
      this.handleRemoveFriend();
    });

    this.$root.$on("ok-revoke-request-modal", () => {
      this.cancelRequest();
    });

    if (this.user) {
      this.$bvModal.show("user-details-modal");
    }
  },

  methods: {
    resetModal() {
      this.sendRequestClicked = false;
      this.moreInfosUser = {};
      this.$store.commit("user/setUserSelectedProfile", null);
    },

    handleAddFriend() {
      FriendsAPI.addFriend(this.user.user.id)
        .then((this.sendRequestClicked = true))
        .catch((e) => console.log(e));
    },
    cancelRequest() {
      if (!this.user.friendship) {
        return;
      }
      FriendsAPI.revokeRequest(this.user.friendship.id)
        .then((this.user.friendship = null))
        .catch((e) => console.log(e));
    },
    handleRemoveFriend() {
      FriendsAPI.removeFriend(this.user.user.id)
        .then(() => {
          this.$emit("friendDeleted");
          this.$bvModal.hide("user-details-modal");
        })

        .catch((e) => console.log(e));
    },
    // Add enable the request sent button to & trigger
    // this once the cancelFriendRequest API is done!
    // handleAbortRequest() {
    //   FriendsAPI.declineFriendRequest(this.requestSent.id).then(res => {
    //     this.$bvModal.hide("abort-request-modal");
    //     this.fetchFriendRequests();
    //   });
    // },
  },
};
</script>
<style lang="scss">
$modal-width: 460px;
$modal-height: 480px;
#user-details-modal {
  .modal-dialog {
    width: $modal-width;
    max-width: none;
    height: $modal-height;
    top: 5%;

    @media (max-height: 750px), (max-width: 1200px) {
      transform: scale(0.9);
      top: -20px;
      margin-bottom: -10%;
    }
  }
  .modal-header {
    border: none !important;
    flex-wrap: wrap;
    justify-content: space-around;
    .close-button {
      right: -25px;
      top: -25px;
    }
  }
  .modal-content {
    width: auto;
    border-radius: 16px;
  }

  #player-info-container {
    padding: 0;

    .gradient-indent {
      border-radius: 8px;
      min-height: 32px;
    }

    #modal-top-row {
      height: 86px;
      .avatar-field {
        height: 100%;
        max-width: 88px;
        overflow: hidden;
        .avatar-img {
          width: 90%;
        }
      }

      .upper-col {
        flex-direction: column;
        text-align: left;
        height: 80px;

        .friend-name {
          font-size: 21px;
          font-family: Ubuntu-Bold;
          color: #fff8b7;
          text-transform: uppercase;
          padding-bottom: 10px;
          max-width: 250px;
        }
      }
    }
    #badge-row {
      height: 56px;
      .badge-icon {
      }
    }
  }

  .small-line {
    line-height: 1;
  }
}
</style>
