<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-levelup-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div class="row">
          <div
            class="col-12"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div class="amount-gold yellow-text">
              {{ formatNumber(rewards[0].gold) }} x
              <div class="icon-gold">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
            </div>
          </div>

          <div
            class="col-12"
            :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
          >
            <div class="amount-crown yellow-text">
              {{ formatNumber(rewards[0].crown) }} x
              <div class="icon-crown">
                <img src="@/assets/img/common/crown6.png" alt="Krone" class="w-100" />
              </div>
            </div>
          </div>

          <div class="col-12 bonus-col">
            <div class="row package-bonus-icons">
              <div
                v-if="rewards[0].card > 0"
                class="col package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
              </div>

              <div
                v-if="rewards[0].loyalty > 0"
                class="col package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
              </div>

              <div
                v-if="rewards[0].elixir > 0"
                class="col package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
              </div>

              <div
                v-if="rewards[0].expbooster > 0"
                class="col package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="submit-button to-center-abs"
        @click="buyItem(0, 'Offer', 'LevelUp')"
      >
        <div>
          {{ $t("shop.offers.buy_button", { 0: "" }) }}
          <br />
          <span class="big">{{ formatPrice(price) }} €</span>
        </div>
      </div>

      <div class="badge-big">{{ $t("shop.offers.badge3") }}</div>

      <div class="badge-small">
        <div>
          <span class="big">1 X</span> {{ $t("shop.offers.badge2", { 0: "" }) }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";

export default {
  name: "ModalOfferLevelup1",
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-levelup-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-levelup-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-levelup.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-levelup-1 {
  .offer-reward-container {
    top: 330px;
    line-height: 1.2;
    text-align: left;
    width: 700px;
  }

  .amount-gold {
    font-size: 65.7px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;
    margin-left: 80px;

    .icon-gold {
      position: absolute;
      width: 66px;
      right: -90px;
      top: 0;
    }
  }

  .amount-crown {
    font-size: 65.7px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;
    margin-left: 80px;

    .icon-crown {
      position: absolute;
      width: 83px;
      right: -100px;
      top: -5px;
    }
  }

  .bonus-col {
    margin-top: 10px;

    .package-bonus-icons {
      padding: 0 10px;
      white-space: nowrap;

      .package-icon {
        width: 93px;
        text-align: center;
      }

      .package-bonus-icon-card {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 46px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .package-bonus-icon-loyalty {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .package-bonus-icon-elixir {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .package-bonus-icon-expbooster {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .submit-button {
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 85px;
    padding-right: 70px;
    display: inline-flex;
    line-height: 1;
    bottom: -70px;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-big {
    width: 181px;
    height: 180px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #4de5ff;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px rgba(77, 229, 255, 0.59));
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0;
    line-height: 1.2;
    position: absolute;
    right: 60px;
    bottom: 155px;

    .big {
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-small {
    width: 143px;
    height: 143px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4de5ff;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px rgba(77, 229, 255, 0.59));
    padding-left: 35px;
    padding-right: 35px;
    line-height: 1.3;
    position: absolute;
    right: 50px;
    bottom: 60px;
    padding-bottom: 10px;

    .big {
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 38px;
    }
  }
}
</style>
