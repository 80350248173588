<template>
  <div>
    <template v-for="tournyItem in updatedTournamentWin">
      <modal-tournament-win
        v-if="tournyItem.id"
        :key="tournyItem.id"
        :updated-tournament-win="tournyItem"
      ></modal-tournament-win> </template
    ><user-details-modal
      v-if="
        $store.getters['user/getUserSelectedProfile'] &&
        $store.getters['user/getUserSelectedProfile'].user.id
      "
    ></user-details-modal
    ><gift-received-modal
      v-if="this.$store.state.chat.latestCardGift"
    ></gift-received-modal
    ><alert-window
      v-if="$store.state.popups.alertWindow.modalId"
    ></alert-window>
    <modal-special-offer v-if="$store.state.shop.specialOffer" />
    <modal-offer-bailout-3
      v-if="offerId === 'modal-offer-bailout-3'"
    ></modal-offer-bailout-3>
    <modal-offer-bailout-2
      v-if="offerId === 'modal-offer-bailout-2'"
    ></modal-offer-bailout-2>
    <modal-offer-bailout-1
      v-if="offerId === 'modal-offer-bailout-1'"
    ></modal-offer-bailout-1>
    <modal-offer-crown-empty-3
      v-if="offerId === 'modal-offer-crown_empty-3'"
    ></modal-offer-crown-empty-3>
    <modal-offer-crown-empty-2
      v-if="offerId === 'modal-offer-crown_empty-2'"
    ></modal-offer-crown-empty-2>
    <modal-offer-crown-empty-1
      v-if="offerId === 'modal-offer-crown_empty-1'"
    ></modal-offer-crown-empty-1>
    <modal-offer-levelup-3
      v-if="offerId === 'modal-offer-levelup-3'"
    ></modal-offer-levelup-3>
    <modal-offer-levelup-2
      v-if="offerId === 'modal-offer-levelup-2'"
    ></modal-offer-levelup-2>
    <modal-offer-levelup-1
      v-if="offerId === 'modal-offer-levelup-1'"
    ></modal-offer-levelup-1>
    <modal-news-center />
    <modal-offer-ab-testing v-if="offerId === 'modal-offer-ab-testing'" />

    <modal-winter-bonus-jagd-winner></modal-winter-bonus-jagd-winner>
  </div>
</template>

<script>
import ModalTournamentWin from "@/components/tournament/modal-tournament-win";
import UserDetailsModal from "@/components/base/user-details-modal";
import GiftReceivedModal from "@/components/chat/gift-received-modal";
import AlertWindow from "@/components/base/alert-window";
import ModalSpecialOffer from "@/components/shop/offers/modal-special-offer.vue";
import ModalOfferBailout3 from "@/components/shop/offers/modal-offer-bailout-3.vue";
import ModalOfferBailout2 from "@/components/shop/offers/modal-offer-bailout-2.vue";
import ModalOfferBailout1 from "@/components/shop/offers/modal-offer-bailout-1.vue";
import ModalOfferCrownEmpty3 from "@/components/shop/offers/modal-offer-crown_empty-3.vue";
import ModalOfferCrownEmpty2 from "@/components/shop/offers/modal-offer-crown_empty-2.vue";
import ModalOfferCrownEmpty1 from "@/components/shop/offers/modal-offer-crown_empty-1.vue";
import ModalOfferLevelup3 from "@/components/shop/offers/modal-offer-levelup-3.vue";
import ModalOfferLevelup2 from "@/components/shop/offers/modal-offer-levelup-2.vue";
import ModalOfferLevelup1 from "@/components/shop/offers/modal-offer-levelup-1.vue";
import offerModal from "@/mixins/offerModal";
import ModalNewsCenter from "@/components/notification/modal-news-center";
import ModalWinterBonusJagdWinner from "@/components/events/winter-bonus-jagd/modal-winter-bonus-jagd-winner";
import ModalOfferAbTesting from "@/components/shop/offers/modal-offer-ab-testing";

export default {
  name: "GlobalPopups",
  components: {
    ModalOfferAbTesting,
    ModalNewsCenter,
    ModalWinterBonusJagdWinner,
    ModalTournamentWin,
    UserDetailsModal,
    GiftReceivedModal,
    AlertWindow,
    ModalSpecialOffer,
    ModalOfferBailout3,
    ModalOfferBailout2,
    ModalOfferBailout1,
    ModalOfferCrownEmpty3,
    ModalOfferCrownEmpty2,
    ModalOfferCrownEmpty1,
    ModalOfferLevelup3,
    ModalOfferLevelup2,
    ModalOfferLevelup1,
  },
  mixins: [offerModal],

  computed: {
    updatedTournamentWin: function () {
      return this.$store.getters["tournaments/getTournamentUpdateWin"];
    },
    offerId() {
      return this.$store.state.shop.offerId;
    },
  },
  mounted() {
    this.setModalEvents();
    this.setTooltipPopupEvents();
  },

  methods: {
    setTooltipPopupEvents: function () {
      this.$root.$on("bv::popover::show", (bvEventObj) => {
        this.createBackgroundElement();
      });

      this.$root.$on("bv::popover::hide", (bvEventObj) => {
        this.removeBackgroundElement();
      });
    },

    setModalEvents: function () {
      this.$root.$on("bv::modal::shown", async (bvEvent, modalId) => {
        this.firebaseSetScreen(modalId, "overlay");
      });

      this.$root.$on("bv::modal::hide", async () => {
        this.playSoundButtonCommon();
      });
    },

    //create dark background for tooltip popover
    createBackgroundElement: function () {
      let background_darken = document.createElement("div");
      background_darken.classList.add("background-popups");
      background_darken.addEventListener("click", () => {
        this.removeBackgroundElement();
        this.$root.$emit("bv::hide::popover");
      });
      document.body.appendChild(background_darken);
    },

    //remove dark background for tooltip popover
    removeBackgroundElement: function () {
      const background_dark =
        document.body.getElementsByClassName("background-popups")[0];
      if (background_dark) {
        document.body.removeChild(background_dark);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
