<template>
  <b-modal
    id="modal-20-percent"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div class="headline to-center-abs">
        <img
          :src="
            require(`@/assets/img/shop/offers/claim-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
      <div class="sub-headline golden-gradient-text to-center-abs">
        {{ $t("shop.popup.20_week.sub_headline") }}
      </div>
      <div class="sub-text to-center-abs">
        {{ $t("shop.popup.20_week.sub_text") }}
      </div>
      <div class="button-submit clickable to-center-abs" @click="openShop">
        {{ $t("shop.popup.20_week.button") }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
export default {
  name: "Modal20Percent",
  components: {},
  mixins: [offerModalContent],

  methods: {
    openShop: function () {
      this.$bvModal.hide("modal-20-percent");
      this.$bvModal.show("modal-shop");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-20-percent {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutgold-1-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }
}
</style>

<style scoped lang="scss">
#modal-20-percent {
  .headline {
    top: 10px;
    width: 615px;
    height: 302px;
  }

  .sub-headline {
    font-size: 72px;
    font-family: Ubuntu-Bold;
    text-transform: uppercase;
    top: 280px;
  }

  .sub-text {
    font-size: 29px;
    font-family: Ubuntu-Medium;
    color: white;
    top: 400px;
    width: 400px;
    line-height: 1.2;
  }

  .button-submit {
    font-size: 30px;
    font-family: Ubuntu-Bold;
    text-transform: uppercase;
    color: white;
    padding: 59px 20px 20px 40px;
    line-height: 1.2;
    top: 530px;
    right: 15px;
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/button-green-no-shine.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }
}
</style>
