<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-daily-3"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/offer-overlay-dealdestages-hl-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <div class="row">
          <div class="col-12">
            <div class="icon-gold">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
          </div>
          <div
            class="col-12"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[0].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </div>

          <div
            class="col-12 text-center"
            :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
          >
            <div class="amount-crown yellow-text">
              {{ formatNumber(rewards[0].crown) }} x
              <div class="icon-crown">
                <img src="@/assets/img/common/crown6.png" alt="Krone" class="w-100" />
              </div>
            </div>
          </div>

          <div class="col-12 bonus-col">
            <div class="row package-bonus-icons">
              <div
                v-if="rewards[0].card > 0"
                class="col package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
              </div>

              <div
                v-if="rewards[0].loyalty > 0"
                class="col package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
              </div>

              <div
                v-if="rewards[0].elixir > 0"
                class="col package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
              </div>

              <div
                v-if="rewards[0].expbooster > 0"
                class="col package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="submit-button to-center-abs"
          @click="buyItem(0, 'Offer', 'Daily')"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[0]) }} €

              <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                {{ formatPrice(oldPrices[0]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[0]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[0] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <div class="row">
          <div class="col-12">
            <div class="icon-gold-container">
              <div class="icon-gold icon-gold1 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold2 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
            </div>
          </div>
          <div
            class="col-12"
            :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[1].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[1].gold) }}
            </div>
          </div>
          <div
            class="col-12 text-center"
            :style="{ visibility: rewards[1].crown > 0 ? 'visible' : 'hidden' }"
          >
            <div class="amount-crown yellow-text">
              {{ formatNumber(rewards[1].crown) }} x
              <div class="icon-crown">
                <img src="@/assets/img/common/crown6.png" alt="Krone" class="w-100" />
              </div>
            </div>
          </div>

          <div class="col-12 bonus-col">
            <div class="row package-bonus-icons">
              <div
                v-if="rewards[1].card > 0"
                class="col package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[1].card) }} x</div>
              </div>

              <div
                v-if="rewards[1].loyalty > 0"
                class="col package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[1].loyalty) }} x</div>
              </div>

              <div
                v-if="rewards[1].elixir > 0"
                class="col package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[1].elixir) }} x</div>
              </div>

              <div
                v-if="rewards[1].expbooster > 0"
                class="col package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[1].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>

          <div
            class="submit-button to-center-abs"
            @click="buyItem(1, 'Offer', 'Daily')"
          >
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[1]) }} €

                <div v-if="oldPrices && oldPrices[1]" class="offer-old-price">
                  {{ formatPrice(oldPrices[1]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[1]"
                  class="big-badge-more-percent smaller"
                >
                  <span class="big">{{ moreContent[1] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="rewards[2]" class="offer-reward-container3">
        <div class="row">
          <div class="col-12">
            <div class="icon-gold-container">
              <div class="icon-gold icon-gold1 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold2 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold3 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
            </div>
          </div>
          <div
            class="col-12"
            :style="{ visibility: rewards[2].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[2].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[2].gold) }}
            </div>
          </div>

          <div
            class="col-12 text-center"
            :style="{ visibility: rewards[2].crown > 0 ? 'visible' : 'hidden' }"
          >
            <div class="amount-crown yellow-text">
              {{ formatNumber(rewards[2].crown) }} x
              <div class="icon-crown">
                <img src="@/assets/img/common/crown6.png" alt="Krone" class="w-100" />
              </div>
            </div>
          </div>

          <div class="col-12 bonus-col">
            <div class="row package-bonus-icons">
              <div
                v-if="rewards[2].card > 0"
                class="col package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[2].card) }} x</div>
              </div>

              <div
                v-if="rewards[2].loyalty > 0"
                class="col package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[2].loyalty) }} x</div>
              </div>

              <div
                v-if="rewards[2].elixir > 0"
                class="col package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[2].elixir) }} x</div>
              </div>

              <div
                v-if="rewards[2].expbooster > 0"
                class="col package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[2].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>

          <div
            class="submit-button to-center-abs"
            @click="buyItem(2, 'Offer', 'Daily')"
          >
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[2]) }} €

                <div v-if="oldPrices && oldPrices[2]" class="offer-old-price">
                  {{ formatPrice(oldPrices[2]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[2]"
                  class="big-badge-more-percent"
                >
                  <span class="big">{{ moreContent[2] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="badge-big">
        <i18next path="shop.offers.badge4a" tag="span"
          ><div class="big" place="0">
            {{ $t("shop.offers.badge4.today") }}
          </div></i18next
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";

export default {
  name: "ModalOfferDaily3",
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-daily-3");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-daily-3 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/offer-overlay-daily-dealdestages-3-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: -10px;
    right: -10px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-daily-3 {
  .headline {
    position: absolute;
    top: -40px;
    left: -35px;
  }
  .offer-reward-container1 {
    top: 200px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 1px;
    height: 300px;

    .icon-gold {
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    .amount-gold {
      font-size: 36px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-top: 7px;
      line-height: 1;

      &.small {
        font-size: 26px;
      }
    }

    .amount-crown {
      font-size: 30px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-left: -48px;

      .icon-crown {
        position: absolute;
        width: 40px;
        right: -50px;
        top: -2px;
      }
    }

    .bonus-col {
      margin-top: 40px;
      padding: 0 14%;
      margin-left: 21px;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;
        padding-right: 50px;
        margin-left: -20px;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 18px;
            margin-top: 5px;
          }

          .icon {
            height: 40px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 18px;
            margin-top: 5px;
          }

          .icon {
            height: 40px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 18px;
            margin-top: 5px;
          }

          .icon {
            height: 40px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 18px;
            margin-top: 5px;
          }

          .icon {
            height: 40px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 160px;
      height: 64px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      bottom: -43px;
      left: -35px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 30px;
      }

      .offer-old-price {
        top: -55px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -390px;
        right: -130px;
      }
    }
  }

  .offer-reward-container2 {
    top: 200px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    right: 51px;
    height: 300px;

    .icon-gold-container {
      height: 75px;

      .icon-gold {
        &.icon-gold1 {
          z-index: 1;
          right: -55px;
          width: 64px;
        }
        &.icon-gold2 {
          z-index: 2;
          width: 80px;
          top: -15px;
          right: 20px;
        }
      }
    }

    .amount-gold {
      font-size: 40px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1.2;

      &.small {
        font-size: 28px;
      }
    }

    .amount-crown {
      font-size: 30px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-left: -48px;

      .icon-crown {
        position: absolute;
        width: 48px;
        right: -50px;
        top: -2px;
      }
    }

    .bonus-col {
      margin-top: 25px;
      padding: 0 10%;

      .package-bonus-icons {
        white-space: nowrap;
        padding-right: 50px;
        margin-left: -20px;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .submit-button {
      width: 200px;
      height: 72px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      bottom: -80px;
      left: -49px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        top: -55px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -440px;
        right: -180px;
      }
    }
  }

  .offer-reward-container3 {
    top: 160px;
    line-height: 1.2;
    text-align: center;
    width: 330px;
    position: absolute;
    left: 287px;
    height: 400px;

    .icon-gold-container {
      height: 80px;

      .icon-gold {
        &.icon-gold1 {
          z-index: 1;
          left: -95px;
          width: 75px;
        }
        &.icon-gold2 {
          z-index: 2;
          width: 96px;
          top: -25px;
        }

        &.icon-gold3 {
          z-index: 1;
          right: -95px;
          width: 75px;
        }
      }
    }

    .amount-gold {
      font-size: 50px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1.3;

      &.small {
        font-size: 38px;
      }
    }

    .amount-crown {
      font-size: 40px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-left: -48px;

      .icon-crown {
        position: absolute;
        width: 48px;
        right: -50px;
        top: -2px;
      }
    }

    .bonus-col {
      margin-top: 30px;
      padding: 0 10%;
      margin-left: -8px;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .submit-button {
      width: 352px;
      height: 182px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      display: inline-flex;
      line-height: 1;
      bottom: -83px;
      left: -40px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }

      .offer-old-price {
        top: -55px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -460px;
        right: -220px;
      }
    }
  }
  .badge-big {
    width: 194px;
    height: 194px;
    background: url(~@/assets/img/shop/offers/badge-big-back4.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 25.2px;
    color: white;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px rgba(77, 229, 255, 0.59));
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0;
    line-height: 1.2;
    position: absolute;
    right: 60px;
    top: -30px;
    transform: rotate(8deg);

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 43.2px;
    }
  }
}
</style>
