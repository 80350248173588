var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.isGameView ? 'resize-page' : '',
    _vm.isLanding ? 'landing' : '',
    _vm.$route.name ? 'site-' + _vm.$route.name : '',
    ((_vm.isSpecialPage && !_vm.$store.getters['auth/isLoggedIn']) ||
      _vm.isManagementPage) &&
    _vm.smallWindow
      ? 'mobile-view'
      : '',
    _vm.$store.state.auth.acpMode ? 'acp-border position-relative' : '' ],attrs:{"id":"app"}},[(_vm.$store.state.auth.acpMode)?_c('span',{staticClass:"text-danger bold position-sticky",attrs:{"id":"acp-warning"}},[_vm._v(" ACHTUNG! ACP-Modus! Bitte achtsam verhalten und unbedingt wieder ausloggen! ")]):_vm._e(),(_vm.showFallBackPage)?_c('fall-back-page'):[_c('MainContent'),_c('broadcasting',{on:{"socketStatus":_vm.handleSocketStatus,"logout":_vm.unsubscribe}}),(_vm.preloadContent)?_c('preloader'):_vm._e(),(_vm.isLoggedIn)?_c('pipeline'):_vm._e(),(_vm.isLoggedIn)?_c('global-popups'):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }