<script>
import AnimationFullRewardEarned from "./animation-full-reward-earned";
import AnimationFullAccomplishment from "./animation-full-accomplishment";

export default {
  name: "AnimationParsingFunctions",
  mixins: [AnimationFullAccomplishment, AnimationFullRewardEarned],

  computed: {
    currentLeague: function () {
      return this.$store.getters["user/getUserLeagueInfos"];
    },
  },
  methods: {
    mapUserLeagueChange: function (data) {
      //get current league
      let current_league = this.currentLeague;
      if (!current_league || !current_league.league) {
        return;
      }

      let reason = data.action;

      //update store
      let newObj = current_league;
      newObj.league = data.newLeague;
      this.$store.commit("user/setUserLeagueInfos", newObj);

      //show animation
      const league_title = data.newLeague.title;
      this.AnimationFullAccomplishmentAnimate(["adel-" + reason, league_title]);
    },

    mapApiJsonCardRedeem: function (data) {
      let result = [];

      if (data.reward.type === "gold") {
        result.push({
          reward: "gold",
          amount: this.formatNumber(data.reward.amount),
        });
      }

      this.AnimationFullRewardEarnedAnimate(result, false);
    },
    mapApiJsonCardUpgrade: function (amount) {
      let result = [];
      result.push({ reward: "elixir", amount: this.formatNumber(amount) });

      this.AnimationFullRewardEarnedAnimate(result, false);
    },

    mapApiJsonChest: function (data) {
      let result = [];

      if (data.gold) {
        result.push({ reward: "gold", amount: this.formatNumber(data.gold) });
      }

      let cards = data.cards;
      if (cards) {
        cards.forEach((card) => {
          let amount = card.amount;
          result.push({
            reward: "card",
            cardId: card.usercard.card.id,
            amount: amount,
          });
        });
      }

      this.AnimationFullRewardEarnedAnimate(result, false);
    },

    mapShopItem: function () {
      let data = this.$store.getters["shop/getBookingResult"];

      let result = [];

      if (data.gold > 0) {
        result.push({
          reward: "gold",
          amount: this.formatNumber(data.gold),
        });
      }

      if (data.crown > 0) {
        result.push({
          reward: "crown",
          amount: this.formatNumber(data.crown),
        });
      }

      if (data.loyalty > 0) {
        result.push({
          reward: "loyalty",
          amount: this.formatNumber(data.loyalty),
        });
      }

      if (data.elixir > 0) {
        result.push({
          reward: "elixir",
          amount: this.formatNumber(data.elixir),
        });
      }
      if (data.expbooster > 0) {
        result.push({
          reward: "expbooster",
          amount: data.expbooster,
        });
      }

      for (let i = 0; i < data.cardRewards.length; i++) {
        result.push(data.cardRewards[i]);
      }

      this.AnimationFullRewardEarnedAnimate(result, false, true);
    },

    mapApiJsonDailyLogin(data) {
      let result = [];

      let amount_gold = 0,
        amount_crown = 0,
        cards = [];

      //get rewards
      for (let props in data) {
        data[props].forEach((el) => {
          switch (el.type) {
            case "gold":
              amount_gold += el.amount;
              break;
            case "crown":
              amount_crown += el.amount;
              break;
            case null:
              el.booked.forEach((card) => {
                cards.push(card);
              });
              break;
          }
        });
      }

      //insert collected rewards in correct format
      if (amount_gold > 0) {
        result.push({
          reward: "gold",
          amount: this.formatNumber(amount_gold),
        });
      }
      if (amount_crown > 0) {
        result.push({
          reward: "crown",
          amount: this.formatNumber(amount_crown),
        });
      }
      for (let i = 0; i < cards.length; i++) {
        result.push({
          reward: "card",
          cardId: cards[i].card.id,
          amount: cards[i].amount,
        });
      }

      this.AnimationFullRewardEarnedAnimate(result, false, false, true);
    },

    mapApiJsonTreasury(data) {
      let result = [];

      let amount_gold = 0,
        amount_crown = 0,
        amount_loyalty = 0,
        amount_elixir = 0,
        cards = [];

      //get rewards
      data.forEach((el) => {
        switch (el.asset.title) {
          case "gold":
            amount_gold += el.amount;
            break;
          case "crowns":
            amount_crown += el.amount;
            break;
          case "loyalty":
            amount_loyalty += el.amount;
            break;
          case "elixir":
            amount_elixir += el.amount;
            break;
          default:
            let card = el.asset;
            let amount = el.amount;
            card.amount = amount;
            cards.push(card);
        }
      });

      //insert collected rewards in correct format
      if (amount_gold > 0) {
        result.push({
          reward: "gold",
          amount: this.formatNumber(amount_gold),
        });
      }
      if (amount_crown > 0) {
        result.push({
          reward: "crown",
          amount: this.formatNumber(amount_crown),
        });
      }
      if (amount_loyalty > 0) {
        result.push({
          reward: "loyalty",
          amount: this.formatNumber(amount_loyalty),
        });
      }
      if (amount_elixir > 0) {
        result.push({
          reward: "elixir",
          amount: this.formatNumber(amount_elixir),
        });
      }

      for (let i = 0; i < cards.length; i++) {
        result.push({
          reward: "card",
          cardId: cards[i].id,
          amount: cards[i].amount,
        });
      }

      this.AnimationFullRewardEarnedAnimate(result, false);
    },

    mapApiJsonBonusSlot(data) {
      let result = [];

      //insert collected rewards in correct format
      if (data.gold > 0) {
        result.push({
          reward: "gold",
          amount: this.formatNumber(data.gold),
        });
      }
      if (data.crowns > 0) {
        result.push({
          reward: "crown",
          amount: this.formatNumber(data.crowns),
        });
      }

      if (data.expbooster > 0) {
        result.push({
          reward: "expbooster",
          amount: data.expbooster,
        });
      }

      for (let i = 0; i < data.cards.length; i++) {
        const cards = data.cards[i];
        for (let j = 0; j < cards.length; j++) {
          result.push({
            reward: "card",
            cardId: cards[j].card.id,
            amount: cards[j].handle_amount,
          });
        }
      }

      if (result.length > 0) {
        this.AnimationFullRewardEarnedAnimate(result, false);
      }
    },

    mapApiJsonUserLeague(data) {
      let rewards = data.rewards;
      let amount = data.amount;
      let result = [];

      rewards.forEach((reward) => {
        let type = reward.reward.asset;

        if (type === "App\\Models\\Asset\\Card") {
          for (let i = 0; i < reward.asset.length; i++) {
            result.push({
              reward: "card",
              cardId: reward.asset[i].card.id,
              amount: reward.asset[i].amount,
            });
          }
        }

        let amount = reward.reward.amount;

        if (type === "App\\Models\\Asset\\Currency" && amount > 500) {
          result.push({
            reward: "gold",
            amount: this.formatNumber(amount),
          });
        }
        if (type === "App\\Models\\Asset\\Currency" && amount <= 500) {
          result.push({
            reward: "crown",
            amount: this.formatNumber(amount),
          });
        }
        if (type === "App\\Models\\Asset\\Point") {
          result.push({
            reward: "loyalty",
            amount: this.formatNumber(amount),
          });
        }
      });

      let callback = () => {
        this.AnimationFullRewardEarnedAnimate(result);
      };
      this.AnimationFullAccomplishmentAnimate(
        ["adel-reward", amount],
        callback
      );
    },

    mapApiJsonFriendReward(data) {
      let result = [];

      const rewards = data.rewards;
      if (rewards.gold > 0) {
        result.push({
          reward: "gold",
          amount: this.formatNumber(rewards.gold),
        });
      }

      if (rewards.crowns > 0) {
        result.push({
          reward: "crown",
          amount: this.formatNumber(rewards.crowns),
        });
      }

      this.AnimationFullRewardEarnedAnimate(result);
    },
    mapApiJsonKingspathComplete(data) {
      let result = [];
      let cards = [];

      data.forEach((el) => {
        if (el.card) {
          let card = el.card;
          card.amount = el.amount;
          cards.push(card);
        }

        if (el.overtakeBonus) {
          result.push({
            reward: "gold",
            amount: this.formatNumber(el.overtakeBonus.amount),
          });
        }
      });

      for (let i = 0; i < cards.length; i++) {
        result.push({
          reward: "card",
          cardId: cards[i].id,
          amount: cards[i].amount,
        });
      }

      this.AnimationFullRewardEarnedAnimate(result, false);
      this.$store.commit("kingspath/setKingspathRewardsCompleted", null);
    },

    mapApiJsonUserLevelUp(data) {
      const newLevel = data.newLevel;
      const rewards = data.rewards;

      let result = [];
      let cardAmount = 0;

      rewards.forEach((el) => {
        if (el.type === "CARD") {
          for (let i = 0; i < el.amount; i++) {
            cardAmount++;
          }
        } else {
          if (el.amount) {
            result.push({
              reward: el.type.toLowerCase(),
              amount: this.formatNumber(el.amount),
            });
          } else {
            result.push({
              reward: el.type.toLowerCase(),
            });
          }
        }
      });

      if (cardAmount > 0) {
        result.push({
          reward: "card",
          amount: cardAmount,
        });
      }

      this.AnimationFullAccomplishmentAnimate(["levelup", newLevel, result]);
    },

    mapApiJsonKronenpassLevelUp(data) {
      const rewards = data.rewards;
      let ticketAmount = data.user.level.level + 1;
      let result = [];

      rewards.forEach((reward) => {
        //expbooster seconds to minutes
        if (reward[0].asset.title === "expbooster") {
          reward[0].amount /= 60;
        }

        result.push({
          reward: reward[0].asset.title,
          amount: this.formatNumber(reward[0].amount),
        });
      });

      if (result.length > 1) {
        ticketAmount *= 2;
      }

      result.push({
        reward: "ticket",
        amount: this.formatNumber(ticketAmount),
      });

      this.AnimationFullAccomplishmentAnimate([
        "kronenpassLevelUp",
        data.user.level.level + 1,
        result,
      ]);
    },

    mapApiJsonKronenpassTierRewards(data) {
      const rewards = data.rewards;
      let ticketAmount = 0;

      let rewardCollection = [];

      let result = [];

      //gather all rewards
      rewards.forEach((reward) => {
        //expbooster seconds to minutes
        if (reward[0].asset.title === "expbooster") {
          reward[0].amount /= 60;
        }
        if (!rewardCollection[reward[0].asset.title]) {
          rewardCollection[reward[0].asset.title] = 0;
        }
        rewardCollection[reward[0].asset.title] += reward[0].amount;
      });

      // push to result array
      for (let key in rewardCollection) {
        result.push({
          reward: key,
          amount: this.formatNumber(rewardCollection[key]),
        });
      }

      // tickets
      for (let i = 0; i <= data.user.level.level; i++) {
        ticketAmount += i;
      }
      result.push({
        reward: "ticket",
        amount: this.formatNumber(ticketAmount),
      });

      this.AnimationFullAccomplishmentAnimate([
        "kronenpassTierRewards",
        ticketAmount,
        result,
      ]);
    },
  },
};
</script>

<style lang="scss"></style>
