<template>
  <b-modal id="password-forget" modal-class="input-window">
    <template slot="modal-header">
      <DecoCorners color-variant="blue" />

      <h3 class="super-headline3-light">
        {{ $t("login.password_forgotten") }}
      </h3>
      <div
        role="button"
        href="#"
        class="close-button"
        @click="$bvModal.hide('password-forget')"
      ></div>
    </template>
    <form class="w-75 mx-auto" @submit.prevent="$emit('confirm-email')">
      <label for="reset-email-input" class="dark-label">{{
        $t("login.password_forgotten.email_prompt")
      }}</label
      ><input
        id="reset-email-input"
        v-model="resetEmail"
        type="email"
        class="form-control dark"
        autocomplete="email"
        required
      />
    </form>
    <template slot="modal-footer">
      <button
        type="submit"
        class="ok-button-green"
        @click="$emit('confirm-email', resetEmail)"
      >
        OK
      </button>
    </template></b-modal
  >
</template>
<script>
import DecoCorners from "@/components/base/deco-corners.vue";

export default {
  name: "ModalPasswordForget",
  components: { DecoCorners },
  props: {
    resetEmail: {
      type: String,
      default: "",
    },
  },
};
</script>
