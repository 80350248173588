export default {
  methods: {
    checkLocale() {
      let preferredLocale;
      // check for language in store first
      if (this.$store.state.locale && this.$store.state.locale !== undefined) {
        preferredLocale = this.$store.state.locale;
      }
      // then check cookies to grab locale info from previous sessions
      else if (
        this.$store.getters["cookieManager/getCookieData"]("kk-locale") !==
        undefined
      ) {
        preferredLocale =
          this.$store.getters["cookieManager/getCookieData"]("kk-locale");
      }

      // check browser language
      else if (window.navigator.languages) {
        preferredLocale = window.navigator.languages[0].split("-")[0];
      } else if (window.navigator.language) {
        preferredLocale = window.navigator.language.split("-")[0];
      } else {
        // use German as default fallback
        preferredLocale = "de";
      }
      this.$store.dispatch("setLocale", preferredLocale);
      this.$i18n.i18next.changeLanguage(preferredLocale);
    },
  },
};
